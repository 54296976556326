<template>
  <div class="">
    <a-input-group compact>
      <a-input style="width: 80%;" v-model="name" disabled placeholder="请查询要货单"></a-input>
      <a-button style="width: 20%;margin: 0;" @click="toSearch" icon="search"></a-button>
    </a-input-group>

    <a-modal :maskClosable="false" title="选择要货单" style="top: 8px;" width="70%" v-model="visible" :footer="null" @cancel="closeModal">
      <a-row>
        <a-col :md="6">
          <div style="display: flex; margin-bottom: 20px">
            <div style="width: 25%; display: flex; align-items: center">要货单号：</div>
            <a-input v-model="params.orderCode" placeholder="要货单号" style="width: 70%;"></a-input>
          </div>
        </a-col>
        <a-col :md="6">
          <div style="display: flex; margin-bottom: 20px">
            <div style="width: 25%; display: flex; align-items: center">需求单号：</div>
            <a-input v-model="params.demandCode" placeholder="需求单号" style="width: 70%;"></a-input>
          </div>
        </a-col>
        <a-col :md="6">
          <div style="display: flex; margin-bottom: 20px">
            <div style="width: 27%; display: flex; align-items: center">经销商名称：</div>
            <a-input v-model="params.customerName" placeholder="经销商名称" style="width: 68%;"></a-input>
          </div>
        </a-col>
        <a-col :md="6">
          <div style="display: flex; margin-bottom: 20px">
            <div style="width: 27%; display: flex; align-items: center">经销商编码：</div>
            <a-input v-model="params.customerCode" placeholder="经销商编码" style="width: 68%;"></a-input>
          </div>
        </a-col>
        <!-- <a-col :md="6">
          <div style="display: flex;">
            <div style="width: 25%; display: flex; align-items: center">订单状态：</div>
            <DictSelect field="buyOrderStatus" :value.sync="params.orderStatus" style="width: 70%" placeholder="订单状态"></DictSelect>
          </div>
        </a-col> -->
        <a-col :md="6">
          <div style="display: flex;">
            <div style="width: 25%; display: flex; align-items: center">生成方式：</div>
            <DictSelect field="sourceType" :value.sync="params.sourceType" style="width: 70%" placeholder="生成方式"></DictSelect>
          </div>
        </a-col>
        <!-- <a-col :md="6">
          <div style="display: flex;">
            <div style="width: 25%; display: flex; align-items: center">确认状态：</div>
            <DictSelect field="confirmStatus" placeholder="确认状态" :value.sync="params.confirmStatus" style="width: 70%"></DictSelect>
          </div>
        </a-col> -->
        <a-col :md="6">
          <a-button @click="toSearch(0)" type="primary" icon="search">查询</a-button>
          <a-button @click="toSearch(1)" type="default" icon="sync">重置</a-button>
        </a-col>
      </a-row>



      <div class="table">
        <a-table size="small" :data-source="tableData" bordered rowKey="id" :pagination="page" @change="p=>{page=p;toSearch(2)}" :customRow="changeTableRow">
          <a-table-column title="要货单号" data-index="orderCode" :width="100" :ellipsis="true" align="left">
          </a-table-column>
          <a-table-column title="订单状态" data-index="orderStatus" :width="100" :ellipsis="true" align="left">
            <template slot-scope="text, record">
              <a-tag v-if="text==0" color="#e6a23c">待审核</a-tag>
              <a-tag v-if="text==1" color="#409eff">已审核</a-tag>
              <a-tag v-if="text==2" color="#409eff">部分发货</a-tag>
              <a-tag v-if="text==3" color="#ff7875">已发货</a-tag>
              <a-tag v-if="text==4" color="#67c23a">已完成</a-tag>
              <a-tag v-if="text==5" color="#909399">已作废</a-tag>
            </template>
          </a-table-column>
          <a-table-column title="收货人" data-index="customerName" :width="100" :ellipsis="true" align="left">
          </a-table-column>
          <a-table-column title="总数/已发" data-index="sendGoodsCount" :width="100" :ellipsis="true" align="left">
            <template slot-scope="text, record">{{record.goodsCount}}/{{record.sendGoodsCount}}</template>
          </a-table-column>
          <a-table-column title="地址" data-index="address" :width="100" :ellipsis="true" align="left">
          </a-table-column>
          <a-table-column title="订单生成方式" data-index="sourceType" :width="100" :ellipsis="true" align="left">
            <template slot-scope="text, record">{{text|sourceType}}</template>
          </a-table-column>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
  const qs = require('qs')
  export default {
    data() {
      return {
        visible: false,
        name: '',
        name2: '',
        tableData: [],
        page: {
          current: 1,
          pageSize: 10,
          total: 0,
          size: 'small'
        },
        params: {}
      }
    },
    props: ['value','customerid'],
    watch: {
      value(newVal) {
        this.name = newVal
      }
    },
    methods: {
      closeModal() {
        this.name2 = ''
        this.params = {}
      },
      toSearch(type) {
        if(!this.customerid){
          return this.$message.warning("请先选择经销商")
        }
        if(type == 1) {
          this.params = {}
        }
        this.visible=true
        const postData = Object.assign({}, {
          customerId:this.customerid,
          pageNumber: type == 2 ? this.page.current : this.page.current = 1,
          pageSize: this.page.pageSize,
          orderStatus: 1,
          ...this.params
        })
        this.axios.get(`/api/order/system/orderInfo/list?${qs.stringify(postData)}`).then(res => {
          this.tableData = res.body.records
          this.page.total = res.body.total
        }).catch(err => {})
      },
      changeTableRow(record) {
        return {
          on: {
            dblclick: e => {
              this.visible = false
              this.$emit('update:value', record.demandCode)
              this.$emit('select', record)
              this.$emit('change')
            }
          }
        }
      },
    },
    created() {
      if (this.value) {
        this.name = this.value
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
